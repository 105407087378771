// gatsby-browser.js
import "./src/css/normalize.css";
import "./src/css/style.css";
import "./src/styles/global.css";

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    // Trengo Script Configuratie
    const loadTrengo = () => {
      window.Trengo = window.Trengo || {};
      window.Trengo.key = 'ZzPnZPtATHqtkZG';
      window.Trengo.sound = false; // Geluid uit
      window.Trengo.render = false; // Voorkom automatisch renderen

      const trengoScript = document.createElement("script");
      trengoScript.src = "https://static.widget.trengo.eu/embed.js";
      trengoScript.async = true;

      trengoScript.onload = () => {
        console.log("Trengo script is geladen.");
        window.Trengo.on_ready = function () {
          console.log('Trengo widget is ready.');
        };
      };

      trengoScript.onerror = () => {
        console.error("Fout bij het laden van het Trengo script.");
      };

      document.head.appendChild(trengoScript);
    };

    // Google Fonts toevoegen
    const loadGoogleFonts = () => {
      const googleFontLink = document.createElement("link");
      googleFontLink.href =
        "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Oswald:wght@200;300;400;500;600;700&display=swap";
      googleFontLink.rel = "stylesheet";
      document.head.appendChild(googleFontLink);
    };

    // Klaviyo Script Configuratie met PUBLIC_API_KEY
    const loadKlaviyo = () => {
      const klaviyoScript = document.createElement("script");
      klaviyoScript.src = "//static.klaviyo.com/onsite/js/QZVast/klaviyo.js"; // Gebruik PUBLIC_API_KEY in de URL
      klaviyoScript.type = "text/javascript";
      klaviyoScript.async = true;

      klaviyoScript.onload = () => {
        console.log("Klaviyo script is geladen.");
        if (typeof window.klaviyo !== "undefined") {
          window.klaviyo.push([
            "identify",
            {
              email: "test@example.com", // Dynamische e-mail van de gebruiker
            },
          ]);
        } else {
          console.error("Klaviyo is niet beschikbaar op window.");
        }
      };

      klaviyoScript.onerror = () => {
        console.error("Fout bij het laden van het Klaviyo script.");
      };

      document.head.appendChild(klaviyoScript);
    };

    // Scripts laden
    loadTrengo();
    loadGoogleFonts();
    loadKlaviyo();
  }
};
